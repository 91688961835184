<script setup>
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    form: {
        type: Object,
        default: false,
    },
});

const classObject = computed(() => ({
    'bg-red': Object.values(props.form.errors).length > 0 && !props.form.processing,
    'bg-green-500 hover:bg-green-700 active:bg-green-700 ':
        Object.values(props.form.errors).length == 0 || props.form.processing,
}));
</script>

<template>
    <button
        :type="type"
        :disabled="form.processing"
        :class="classObject"
        class="
            relative
            px-4
            py-2
            text-xs
            font-semibold
            leading-tight
            text-white
            uppercase
            transition
            border border-transparent
            rounded-md
            min-h-10 min-w-65
            focus:outline-none focus:ring focus:ring-gray-400
            disabled:opacity-25
            flex-center
        "
    >
        <slot />
    </button>
</template>
